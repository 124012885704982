.Coupon-Container-Home:hover {
    border-radius: 15px;
    background: #f6f6f6;
}

.Coupon-Container-Home {
    width: 85% ;
    position: relative;
    margin: 18px;
    border-radius: 4px;
    border: thin solid rgb(224, 224, 224);
    overflow: hidden;
    background: #fff;
    box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px;
    color: #4b4b4b;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    height: 230px;
}

.Image-Container-Home {
    width: 30%;
    height: 100%;
    overflow: hidden;
    background: white;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border-right: thin solid rgb(224, 224, 224);

}

.Discount-Container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width:50%
}

.Discount-Container span:first-child {
    font-size: 32px;
    font-weight: 900;
    color: #f17c2f;
}

.Discount-Container span:nth-child(2) {
    font-size: 18px;
    font-weight: 900;
    color: #f17c2f;
}

.Discount-Container span:nth-child(3) {
    font-size: 16px;
    font-weight: 600;
    color: grey;
    margin-top: 5px;
    text-transform: uppercase;
}

.Image-Container-Home img {
    width: 50%;
    padding-left: 15px
}

.Coupon-Desc-Home{
    width: 45%;
    position: relative;
    padding: 8px 15px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
}

.Coupon-Cat-Home{
    position: relative;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4b4b4b;
}

.Coupon-Title-Home{
    position: relative;
    display: -webkit-box;
    min-height: 44px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.375em;
    font-size: 20px;
    font-weight: 900;
    color: #ea5e2c;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.Coupon-Time-Home{
    position: relative;
    font-size: 11px;
    margin-bottom: 1px;
    color: rgb(50, 50, 50);
}

.Coupon-CTA-Container{
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: rgb(38, 57, 77) 0px 19px 30px -16px;
    cursor: pointer;
    border-radius: 3px;

}

.Coupon-CTA-Container .code {
    border: 1px dashed blue;
    background: #edf2ff;
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    font-size: 20px;
    font-weight: 900;
}

.Coupon-CTA-Container .hide {
    background: #f17c2f;
    color: white;
    width: 85%;
    position: absolute;
    z-index: 10000;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    transition: width .1s;
    font-weight: 600;
    border-radius: 3px;
}

.Coupon-CTA-Container .hide:hover {
    width: 75%;
}

.Coupon-CTA-Container button{
    border: thin solid #f17c2f;
    background: #f17c2f;
    color: white;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    z-index: 10000;
    height: 100%;
    width: 100%;
    padding: 1px;
    font-weight: 600;
}

@media screen and (max-width: 1465px){
    .Coupon-Container-Home{
        width: 100%;
    }
    .Image-Container-Home {
        width: 20%;
    }
}

@media screen and (max-width: 478px){
    .Coupon-Container-Home{
        height: auto;
        flex-direction: column;
        width:100%
    }
    .Image-Container-Home {
        width: 100%;
        margin-top:20px;
        border-right: none;
        border-bottom: thin solid #e0e0e0;
    }

    .Coupon-Desc-Home{
        width: 100%;
    }

    .Coupon-CTA-Container{
        width: 100%;
    }

    .Coupon-CTA-Container button{
        width: 100%;
        font-size: 14px;
    }

    .Image-Container-Home img {
        width: 85%;
    }

    .Discount-Container {
        display: none;
    }
}
