.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    background-color: #ea5e2c;
    bottom: 0;
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 6px;
    padding-top: 6px;
    box-shadow: 0px -7px 15px #ea5e2c;
}

.Footer-Logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Footer-Logo p{
    margin-right: 10px;
}

.Footer-Links {
    display: flex;
    justify-content: space-between;
    width: 300px;
    padding: 0 10px;
    text-decoration: none;
}

.Footer-Links a {
    color: white;
    text-decoration: none;
    font-weight: bolder;
}
.Footer-Links a:visited {
    color: white;
}

@media screen and (max-width: 478px){
    .Footer{
        flex-direction: column;
        height: auto;
    }
}