.Privacy-Container {
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: 40px auto;
    margin-top: 0;
    text-align: left;
}

.Privacy-Container p {
    margin-top: 10px;
    margin-bottom: 0px;
}

.Contact-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: 40px auto;
    margin-top: 0;
    text-align: left;
    justify-content: center;
    height: 70vh;
}