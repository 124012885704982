.Carousel-Container{
    margin-top: 20px;
    width: 75vw;
}

.Coupon-Container {
    width: 100%;
    position: relative;
    margin: 18px;
    border-radius: 7px;
    border: thin solid rgb(224, 224, 224);
    overflow: hidden;
    background: #fff;
    box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px;
    color: #4b4b4b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.Coupon-Container:hover {
    transform: scale(1.08);
}

.Image-Container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border-radius: 7px 7px 0 0;
}

.Image-Container img {
    width: 50%;

}

.Coupon-Desc{
    position: relative;
    padding: 25px 15px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
}

.Coupon-Cat{
    position: relative;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4b4b4b;
}

.Coupon-Title{
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.375em;
    font-size: 24px;
    font-weight: 800;
    color: rgb(50, 50, 50);
    text-align: left;
}

.Coupon-Time{
    position: relative;
    font-size: 11px;
    margin-bottom: 1px;
    color: rgb(50, 50, 50);
}

.swiper-button-next, .swiper-button-prev {
    color:  #ea5e2c;
}

@media screen and (max-width: 1465px){
    .Coupon-Title{
        -webkit-line-clamp: 2;
    }
}

@media screen and (max-width: 768px){
    .Coupon-Container {
        width: 80%;
    }

    .Image-Container img {
        width: 80%;
    }
}
